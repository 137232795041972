import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import KtLayout from '@solid-ui-blocks/KtLayout/KtLayout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import httpService from '../../../service/httpService'; 

import Fiyat from '@solid-ui-blocks/Pricing/Fiyat'
import KtLog from '../../../../../packages/solid-ui/solid-ui-blocks/src/KtLog/KtLog'
import CanliDestek from '@solid-ui-blocks/CanliDestek/CanliDestek'


const LisansPaketleri = props => {


  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
 
  const [paketListesi, setList] = useState([]);


  useEffect(() => { 

    httpService.post("UnLocked/PaketList", { }).then((res) => {
      if (res.DogruMu) {
        res.Data[0].textGroup = res.Data.map(function (v) {
          return v.UyelikTipiText;
        });
        res.Data.forEach(ktpaketList => {
          ktpaketList.PaketIcerikleri.forEach(paketIcerikList => {
            paketIcerikList.KrediListesi[0].SeciliMi = true;
          });
        });
        setList(res)
      } else {
       // alert(res.Mesaj);
      }
    }).catch((err) => {
    
    });
  }, [])

  return (
    <KtLayout prp={props} cnt={content}  lightmi={false} >
      <Seo title='Lisans İşlemleri Packing Standart' />
      <Divider space='5' /> 
      <KtLog>
      <Fiyat content={paketListesi} />
      </KtLog>
      <Divider space='5' /> 
      <CanliDestek></CanliDestek>
    </KtLayout>
  )
}

export const query = graphql`
  query innerpageLisansPaketleriBlockContent { 
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    } 
  }
`


export default LisansPaketleri
